

(() => {
    const physicians = document.querySelectorAll('.physician')


    physicians.forEach(physician => {
    
        physician.addEventListener('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            this.classList.add('expanding');
        })
    
    
        physician.addEventListener("transitionend", function (e) {
            if (e.target !== physician) return;
            if (physician.classList.contains('expanding')) {
                this.querySelector('.info').scrollIntoView();
                window.location.hash = `#${this.id}`
            }
            if (physician.classList.contains('collapsing')) {
                window.location.hash = `#${physician.id.split('-info')[0]}`
            }
        });
    
        const backLink = physician.querySelector('.back-link')
        backLink.addEventListener('click', function (e) {
            if (physician.classList.contains('expanding') ||
                physician.classList.contains('collapsing')) {
                physician.classList.remove('expanding');
                physician.classList.add('collapsing');
            }
    
            e.stopPropagation();
        })
    })
    
    
  })();